import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes : Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView,

		children: [
			{
				path: '/Index',
				name: 'Index',
				component: () => import('../views/Index.vue')
			},
			{
				path: '/Aboutus',
				name: 'Aboutus',
				component: () => import('../views/Aboutus.vue')
			},
			{
				path: '/Cases',
				name: 'Cases',
				component: () => import('../views/Cases.vue')
			},
			{
				path: '/Product',
				name: 'Product',
				component: () => import('../views/Product.vue')
			},
			{
				path: '/Service',
				name: 'Service',
				component: () => import('../views/Service.vue')
			 }
		]
	},
	{
		path: '/Map',
		name: 'Map',
		component: () => import('../views/Map.vue')
	}
	


]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, from, next) => {
	//判断是否进入/home路径
	if (to.path == '/') {
		next({ path: '/Index' })
	}
	else {
		next()
	}
})
export default router