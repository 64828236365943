<template>
	<div class="bottom">
		<div class="bottom_Contact flexrow">
			<div class="bottom_Contact_map flexcol1">
				<div class="company_info">
					<div>浙江创基电子有限公司</div>
					<div>电话： 18969382277&nbsp;&nbsp;0579-89286388</div>
					<div>邮编：321300</div>
					<div>地址：浙江省永康市西城街道玉桂路21号</div>
				</div>
			</div>
        <div class="bottom_Contact_official flexcol1">
			<h4>创基服务号：</h4>
			<img src="../assets/Bottom/official1.jpg" alt="创基伟业">
			
		</div>
		<div class="bottom_Contact_official flexcol1">
			
			<h4>技术学习：</h4>
			<img src="../assets/Bottom/official2.jpg">
			
		</div>
		</div>
		<div class="htmlinfo">
			<span class="htmlinfo_text">创基 科技成就三农之美</span>
			<span class="htmlinfo_text">最忆是乡愁，地方特色产品</span><br />
			<div>版权所有：浙江创基电子有限公司</div>
			<div class="htmlinfo_a">备案号：<a href="https://beian.miit.gov.cn">浙ICP备16018852号-4</a></div>
			
			<el-backtop class="backtop"/>
		</div>
	</div>
</template>

<style scoped>
	/*企业信息*/
	.bottom_Contact {
		width: 100%;
		margin: 0px auto;
		
		background-color: #efefef;
		font-size: 1rem;
		height: 15rem;
		line-height: 2rem;
	}

	.bottom_Contact_map img {
		text-align: center;

	}

	.bottom_Contact_text {
		width: 50%;
	}

	.app-container {
		height: 100%;
	}
    .bottom_Contact_official img{
		width: 10rem;
		height: 10rem;
		margin-left: 3rem;
	}
    .bottom_Contact_official h4{
		margin-left: 3rem;
	}
	/*底部*/
	.htmlinfo {

		text-align: center;
		height: 6rem;
		font-size: 0.8rem;
		background-color: dimgrey;
		color: darkgray;
		line-height: 2rem;
	}

	.htmlinfo_text {
		margin-left: 3rem;
	}

	.company_info {
		text-align: center;
		margin-top: 3rem;
		
	}
	a{
		color: darkgray;
		text-decoration: none;
	}
	a:hover{
		text-decoration: underline;
		color: blue;
	}
	
	@media screen and (max-width: 900px) {
		.bottom_Contact {
			width: 100%;
			margin-top: 2rem;
			font-size: 4rem;
			height: 30rem;
			line-height: 6rem;
		}
		.bottom_Contact_official img{
			width: 12rem;
			height: 12rem;
			margin-left: 0rem;
		}
		.bottom_Contact_official h4{
			margin-left: 1rem;
		}
		.htmlinfo {
			height: 15rem;
			font-size: 2rem;
			line-height: 5rem;
		}
		.backtop{
			width: 3rem;
			height: 4rem;
		}
	}
	
</style>