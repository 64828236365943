
import { defineComponent  } from 'vue';
import top from '@/components/Top.vue'; // @ is an alias to /src
import bottom from '../components/Bottom.vue';
export default defineComponent({
  name: 'HomeView',
  components: {
    top,bottom
  }
});
