<template>

	<router-view />
</template>

<style>
	.flexrow {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
	}

	.flexcol1 {
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: auto;
	}

	.flexcol5 {
		flex-grow: 5;
		flex-shrink: 0;
		flex-basis: auto;
	}

	p {
		text-indent: 2em;
	}
</style>