import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top = _resolveComponent("top")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom = _resolveComponent("bottom")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_top),
    _createVNode(_component_router_view),
    _createVNode(_component_bottom)
  ]))
}