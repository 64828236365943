
	import { defineComponent, ref, reactive } from 'vue';
	//import { ElButton, ElDrawer } from 'element-plus';

	import {useRouter} from "vue-router";

	export default defineComponent({
		name: 'Top',
		setup() {
			//const activeIndex = ref('1')
			const visible = ref(false)
			const imglist = reactive([
				{
					id: 1,
					imgurl: require('../assets/top/Carousel/1.png')
				},
				{
					id: 2,
					imgurl: require('../assets/top/Carousel/2.png')
				},
				{
					id: 3,
					imgurl: require('../assets/top/Carousel/3.png')
				},
				{
					id: 4,
					imgurl: require('../assets/top/Carousel/4.png')
				}
			])
         function detail(a:number){
			 const router = useRouter();
			 router.push("/product")
		 }
			return {
				imglist,
				visible,
				//activeIndex,
				detail
			}
		}

	});
