import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import '../libs/rem.js'
import "echarts-extension-amap";
import '../src/assets/top/iconfont/iconfont.css'
import '../src/assets/top/iconfont/iconfont.js'

// 全局挂载echarts
import * as echarts from 'echarts'
const app=createApp(App)
app.config.globalProperties.$echarts = echarts
app.use(store)
app.use(router)
app.use(ElementPlus)
app.mount('#app')

